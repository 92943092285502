

















































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ListingStatus, Office, Profile, WebsiteLevel, MapSettings, WebsiteProfileDisplayType, Context } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { ListingFilter, ProfileFilter } from 'client-website-ts-library/filters';
import { ContextItemType } from 'client-website-ts-library/types/Context';

import Listings from '@/components/Listings.vue';
import TestimonialList from '@/components/Testimonials/TestimonialList.vue';
import Loader from '@/components/UI/Loader.vue';
import ProfileCards from '@/components/ProfileCards.vue';
import Awards from '@/components/Awards.vue';
import OfficeListingSearch from '@/components/OfficeListingSearch.vue';
import Form from '@/components/Forms/Form.vue';

const Map = () => import('client-website-ts-library/components/Map/Map.vue');

@Component({
  components: {
    Listings,
    TestimonialList,
    Loader,
    ProfileCards,
    Awards,
    OfficeListingSearch,
    Map,
    Form,
  },
})
export default class HeadOfficeDetails extends Mixins(AsyncView) {
  private office: Office | null = null;

  private profiles: Profile[] = [];

  private hasSoldListings = true;

  private hasCurrentListings = true;

  private hasTestimonials = true;

  private listingFilter: ListingFilter | null = null;

  private mapSettings: MapSettings | null = null;

  private formContext: Context = {
    ClientWebsiteId: Config.Website.Id,
    Items: [
      {
        Type: ContextItemType.EnquiryTargetProfile,
        Id: 'c7894578-a495-4cc1-a0b3-656f0684b2c0',
      },
    ],
  }

  get directors(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Principal);
  }

  get commercialAgents(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.CommercialAgent);
  }

  get salesAgents(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Agent);
  }

  get propertyManagers(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PropertyManager);
  }

  get support(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PersonalAssistant);
  }

  stripHtml(html: string): string {
    const el = document.createElement('div');

    el.innerHTML = html;

    return el.innerText;
  }

  head(): RouteMeta | null {
    if (this.office === null) return null;

    let about = this.stripHtml(this.office.About);

    if (about.length > 156) {
      about = `${about.substring(0, 156)}...`;
    }

    return {
      title: `${this.office.OfficeName}`,
      description: about,
      image: this.office.WebsitePhotoPreview.Url ? this.office.WebsitePhotoPreview.Url : undefined,
    };
  }

  updateOffice(id: string) {
    this.mapSettings = null;

    API.Offices.Get(id).then((office) => {
      this.office = office;

      if (this.listingFilter != null) {
        const filter = new ListingFilter({
          SearchLevel: WebsiteLevel.Office,
          SearchGuid: office.Id,
          Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
        });

        this.handleAliases(filter);

        this.listingFilter = filter;
      }

      this.mapSettings = new MapSettings({
        Zoom: 20,
        Interactive: false,
        Padding: 80,
        Markers: [
          {
            Title: `${office.FranchiseName} ${office.OfficeName}`,
            Address: `${office.AddressLine1} ${this.office?.AddressLine2}`,
            Coordinate: office.Coordinate,
          },
        ],
      });
    });

    const profileFilter = new ProfileFilter();
    profileFilter.SearchLevel = WebsiteLevel.Office;
    profileFilter.SearchGuid = id;

    API.Profiles.Search(profileFilter, true).then((profiles) => {
      this.profiles = profiles;
    });

    this.$emit('updateView');
  }

  /* eslint-disable no-param-reassign */
  handleAliases(filter: ListingFilter) {
    // Alias offices
    const aliasOffices = [
      'f08cd028-2793-4599-88b1-dba1c8f27da2', // Magnetic Island
      'c3603166-658d-4056-9850-6cba69651fe9', // Brownsville North
    ];

    if (aliasOffices.indexOf(filter.SearchGuid) !== -1) {
      const profileIds = this.profiles.map((p) => p.Id);

      filter.SearchLevel = WebsiteLevel.Profile;
      filter.SearchGuid = profileIds.shift()!;
      filter.SearchGuids = profileIds;
      filter.PageSize = 21; // Larger page size for profile search
    }
  }
  /* eslint-enable */

  gotFilter(filter: ListingFilter) {
    this.handleAliases(filter);

    this.listingFilter = filter;
  }

  mounted() {
    this.updateOffice('b6b5cc8c-42db-4fea-884a-f494ae7a98d3');
  }
}
